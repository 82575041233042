$primary: #161e47;
$primary-light : #1d2759;
$text: #414766;
$text-light: #8589ac;
$ease-custom: cubic-bezier(.06, .53, .25, 1.01);
$grey: #F6F5F6;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.text-right {
  text-align: right;
}



.embed-image.logo-left {
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    background: #F1F1F1;
    height: 100%;
    left: 42px;
    z-index: -1;
    top: 0;
  }
}

.about-page .right-side.col {
  position: relative;
}


body {
  color: $primary;
  font-family: "DM Sans", sans-serif;
}

.animated-line{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0.5;
  transform: translateX(-130%) translateY(-50%) rotateZ(9deg) ;
  .path {
    stroke-dasharray: 680;
    stroke-dashoffset: 680;
    animation: draw 1s cubic-bezier(.04,.42,.41,.99) forwards;
    animation-delay: 0.4s;
  }
  svg{
    width: 200px;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }


}

p {
  color: $text;
  font-size: 18px;
  line-height: 1.6em;
}

a {
  color: $text;
}


.Wrap-Content.fade-enter {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.Wrap-Content.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
}



.no-underline {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

video {
  border: 1px solid #f0ebeb;

  &.grayscale {
    filter: grayscale(0.3);
  }
}

.border_r {
  border-radius: 10px;
  border: 1px solid #f0ebeb;
  overflow: hidden;
}


.error-message {
  font-size: 12px;
  padding: 10px 0;
  background: #FAFAFA;
  margin-bottom: 10px;
}

.nav-wrap a {
  opacity: 0.7;

  &.active {
    opacity: 1;
  }
}

.React {
  position: fixed;
  bottom: 0.5vh;
  right: -5px;
  width: 80px;
  z-index: 100;
}

.Community-Listing .community-slider .slick-slide svg {
  margin-left: 0;
}

ul.slick-dots li:after {
  display: none;
}

.community-slider {
  .slick-prev {
    left: -36px !important;
  }

  .slick-next {
    right: -36px !important;
  }

  .wrap {
    overflow: hidden;
  }

  a {
    display: block;
    cursor: pointer;
  }

  .thumbnail {
    position: relative;

    &:hover {
      .external {
        transform: translateX(-50%) translateY(-50%) scale(1);
      }
    }
  }

  .external {
    position: absolute;
    top: 50%;
    left: 50%;
    background: $primary;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: all 0.5s $ease-custom;
    transform: translateX(-50%) translateY(-50%) scale(0);

  }





  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    margin: 0 10px;

    .information {
      padding: 0px 0;
    }

    .label-wrap {
      margin-top: 0;
      margin-bottom: 1.3rem;
    }

    svg {
      margin-left: 0.5rem;
    }

    .primary {
      text-decoration: none;
      display: inline-block;
    }

    img {
      height: auto;
      object-fit: cover;
      object-position: top;

    }

    h4 {
      font-size: 1.1rem;
      margin: 0rem;
      margin-bottom: 1rem;
    }
  }

  .slick-list {
    margin: 0 -10px;
  }
}


.back-button-empty {
  background-color: transparent;
  border: none;
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  color: $primary;
  display: block;
  margin: 0 auto;
  margin-top: 16px;

}

// image loading 

.portfolio-grid-skeleton {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.portfolio-item-skeleton {
  aspect-ratio: 16/9;
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.skeleton-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%);
  animation: shimmer 1.5s infinite;
}

.single-portfolio {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s $ease-custom, transform 0.5s $ease-custom;
}

.single-portfolio.fade-in {
  opacity: 1;
  transform: translateY(0);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.error-container {
  text-align: center;
  padding: 2rem;
  background: #fff5f5;
  border-radius: 8px;
  margin: 1rem 0;
}

.error-container button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s $ease-custom;
}

.error-container button:hover {
  background: #c53030;
}

// portfolio skeleton end claud 





.image-loading {
  opacity: 0;
  transition: opacity 0.3s $ease-custom;
  filter: blur(10px);
}

.image-loaded {
  opacity: 1;
  filter: blur(0);
  transition: all 0.3s $ease-custom;
}



/* Optional loading placeholder */
.image-loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}


// lazy load about images

// .about-page {
//   opacity: 0;
//   animation-delay:0s !important;
//   animation: fadeIn 0.5s $ease-custom forwards;
// }

.BannerAbout {
  padding: 4rem 0;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  min-height: 650px;
  transition-delay: 0s !important;
  transition: opacity 0.5s $ease-custom, transform 0.5s $ease-custom;
}

.BannerAbout.loaded {
  opacity: 1;
  transform: translateY(0);
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.image-container img {
  opacity: 0;
  transition: opacity 0.3s $ease-custom;
}

.image-container img.fade-in {
  opacity: 1;
}

.name-heading,
.location-heading {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s $ease-custom forwards;
}

.location-heading {
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .BannerAbout {
    padding: 2rem 0;
  }

  .banner-content {
    text-align: center;
    margin-bottom: 2rem;
  }

  .location-heading {
    justify-content: center;
  }
}


// home banner 

.content-block .video-list {
  align-items: flex-start;
}

.Banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
  position: relative;
}

.banner-wrapper {
  opacity: 0;
  animation: fadeIn 0.5s $ease-custom forwards;
}

.banner-title {
  overflow: hidden;
}

.banner-paragraph {
  margin-top: 3rem;
}

.row-archive {
  opacity: 0;
  transform: translateY(20px) scale(1);
  transition-delay: 2s;
  transition: all 0.7s $ease-custom !important;
}

.row-archive.fade-in {
  opacity: 1;
  transform: translateY(0px) scale(1);
}



/* StarRating.css */
.rating-popup {
  position: fixed;
  left: 16px;
  bottom: 16px;
  background: white;
  border-radius: 8px;
  max-width: 320px;
  animation: slideUp 0.3s ease-out;
  border: 1px solid #FAFAFA;
  box-shadow: 0 4px 6px 3px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  z-index: 1000;
}

.rating-container {
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #f3f4f6;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: #e5e7eb;
  transform: scale(1.1);
}

.close-button svg {
  width: 16px;
  height: 16px;
  stroke: #6b7280;
}

.rating-content {
  text-align: center;
}

.rating-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 4px 0;
  color: $primary;
}

.rating-description {
  font-size: 14px;
  color: #6b7280;
  margin: 0 0 12px 0;
}

.stars-container {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 8px;
}

.star-button {
  font-size: 24px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #d1d5db;
  line-height: 1;
}

.star-button:hover,
.star-button.active {
  color: #fbbf24;
  transform: scale(1.25);
}

.star-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  color: #6b7280;
}

.spinner {
  animation: spin 1s linear infinite;
  width: 16px;
  height: 16px;
  border: 2px solid $primary;
  border-top-color: transparent;
  border-radius: 50%;
}

/* Success state */
.success-popup {
  text-align: center;
}

.success-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto 12px;
  color: #10b981;
}

.success-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 8px 0;
  color: #1f2937;
}

.success-description {
  font-size: 14px;
  color: #6b7280;
  margin: 0 0 12px 0;
}

.close-success-button {
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}




// feedback popup 

/* Base styles */
.rating-popup {
  position: fixed;
  left: 16px;
  bottom: 16px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out forwards;
}

.rating-container {
  background: white;
  border-radius: 8px;
  padding: 16px;
  max-width: 320px;
  transform-origin: bottom left;
  animation: scaleIn 0.3s ease-out forwards;
}

/* Close button */
.close-button {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #f3f4f6;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: #e5e7eb;
  transform: scale(1.1);
}

.close-button svg {
  width: 16px;
  height: 16px;
  stroke: #6b7280;
}

/* Rating content */
.rating-content {
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.3s ease-out 0.1s forwards;
}

.rating-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 4px 0;
  color: #1f2937;
}

.rating-description {
  font-size: 14px;
  color: #6b7280;
  margin: 0 0 12px 0;
}

/* Stars container */
.stars-container {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 8px;
}

.star-button {
  font-size: 24px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #d1d5db;
}

.star-button:hover,
.star-button.active {
  color: #fbbf24;
  transform: scale(1.25);
}

/* Textarea styles */
.textarea-container {
  opacity: 0;
  transform: translateY(10px);
  animation: slideUpFade 0.3s ease-out forwards;
}

.suggestions-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
  line-height: 1.5;
  min-height: 100px;
  background: #f9fafb;
  transition: all 0.2s ease;
  margin-bottom: 4px;
}

.suggestions-textarea:focus {
  outline: none;
  border-color: #3b82f6;
  background: white;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Character counter */
.char-counter {
  text-align: right;
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 8px;
  transition: color 0.2s ease;
}

.char-counter.warning {
  color: #f59e0b;
}

/* Button group */
.button-group {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  opacity: 0;
  transform: translateY(10px);
  animation: slideUpFade 0.3s ease-out 0.1s forwards;
}

.submit-button,
.back-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button {
  background: #3b82f6;
  color: white;
  border: none;
  flex: 2;
}

.submit-button:hover {
  background: #2563eb;
  transform: translateY(-1px);
}

.back-button {
  background: #f3f4f6;
  color: #4b5563;
  border: 1px solid #d1d5db;
  flex: 1;
}

.back-button:hover {
  background: #e5e7eb;
  transform: translateY(-1px);
}

/* Success state */
.success-popup {
  text-align: center;
  opacity: 0;
  animation: scaleIn 0.3s ease-out forwards;
}

.success-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto 12px;
  color: #10b981;
}

/* Loading state */
.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  color: #6b7280;
  margin-top: 12px;
  opacity: 0;
  animation: fadeIn 0.2s ease-out forwards;
}

.spinner {
  animation: spin 1s linear infinite;
  width: 16px;
  height: 16px;
  border: 2px solid #3b82f6;
  border-top-color: transparent;
  border-radius: 50%;
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUpFade {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .rating-popup {
    left: 8px;
    right: 8px;
    bottom: 8px;
  }

  .rating-container {
    max-width: none;
    margin: 0 auto;
  }

  .star-button {
    font-size: 20px;
  }
}

// feedback end 


.mt-4.pt-4.mb-4.pb-4.archive-wrapper {
  min-height: 100vh;
}

// image loading 
img.loading {
  opacity: 0;
}

img.loaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

// image loading 

.banner-text-animated {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.7s $ease-custom forwards;
  margin: 0;
  display: block;
}

h1.banner-text-animated {
  line-height: 1.1;
  margin-bottom: 0rem;
  font-size: 93px !important;
}

.right-banner {
  position: relative;
}

.images {
  position: relative;
}

.Main-Image {
  max-width: 100%;
  height: auto;
  opacity: 0;
  transform: translateX(20px);
  animation: slideUp 0.5s $ease-custom forwards;
  animation-delay: 0s;

  &.scroll-img {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
  }
}

.main-image-container {
  &:hover {
    .main-img {
      object-position: bottom !important;
    }
  }

  &:after {
    content: '';
    background: red;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    left: 0;
    opacity: 0;
  }

  &.scrolled-half {
    .main-img {
      object-position: bottom !important;
    }
  }

}






.rotation {
  position: absolute;
  bottom: -20px;
  right: -20px;
  opacity: 0;
  animation: fadeRotateIn 0.5s $ease-custom forwards;
  animation-delay: 0.4s;
}

.rotating-text-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.rotating-text-svg {
  animation: spin 20s linear infinite;
  width: 100%;
  height: 100%;
}

.rotating-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.rotating-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// locked screen backgroung image 

.locked-portfolio-container {
  overflow-x: hidden;
  position: relative;

  .locked-background {
    opacity: 0.1;
    position: absolute;
    height: 80vh;
    bottom: 0;
    z-index: 0;
    right: -10%;
  }

  .locked-portfolio-form {
    position: relative;
    z-index: 1;
  }
}



@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeRotateIn {
  from {
    opacity: 0;
    transform: rotate(-180deg);
  }

  to {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .Banner {
    flex-direction: column;
    padding: 2rem 0;
  }

  .banner-title {
    text-align: center;
  }

  h1.banner-text-animated {
    font-size: 2.5rem;
  }

  .w-50 {
    width: 100% !important;
    text-align: center;
  }

  .right-banner {
    margin-top: 2rem;
    width: 100%;
  }

  .rotation {
    bottom: -10px;
    right: -10px;
  }
}

// image loading end 


@media (min-width: 992px) {
  .container {
    max-width: 1200px !important;
  }
}

.portfolio-Banner {
  width: 100%;
  text-align: left;
  margin: 5rem !important;
  width: calc(100% - 10rem);
  border-radius: 30px;

  .side-info {
    padding: 4rem;
    padding-bottom: 0;

    h2 {
      margin: 1.5rem 0;
    }
  }

  .data-top {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    margin-top: 2rem;

    span {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      // background-color: rgba(0,0,0,0.03);
      width: auto;
      position: relative;

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '|';
        position: absolute;
        right: -1rem;
        opacity: 0.3;
      }
    }
  }

  .data-bottom {
    display: flex;
    margin-top: 2rem;
    gap: 1.5rem;
    margin-bottom: 3rem;

    .single-info {
      padding: 1.5rem;
      border-radius: .75rem;
      background-color: rgba(0, 0, 0, 0.05);
      width: 33.33%;

      .info-title {
        display: flex;
        gap: .75rem;
        margin-bottom: .75rem;
      }

      .info-value {
        text-align: left;
      }
    }

  }

  p {
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
  }
}

.w-80 {
  width: 70%;
}

.HomePage {
  padding-top: 10rem;
}



.locked-portfolio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.locked-portfolio-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;

  .form-header {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: $primary;
    }

    p {
      font-size: 16px;
      color: $text-light;
    }
  }

  .input-group {
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 12px 15px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 16px;
      transition: border-color 0.3s $ease-custom;

      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }

  .error-message {
    background-color: #fff5f5;
    color: #dc3545;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .unlock-button {
    width: 100%;
    padding: 12px;
    background-color: $primary;
    color: white;
    border: none;
    border-radius: 100px;
    font-size: 16px;
    cursor: pointer !important;
    transition: background-color 0.3s $ease-custom;

    &:hover {
      background-color: $primary;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }
  }

  .back-button {
    width: 100%;
    padding: 12px;
    background-color: transparent;
    color: $text;
    border: 1px solid #ddd;
    border-radius: 100px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s $ease-custom;

    &:hover {
      background-color: #f5f5f5;
      border-color: #999;
      color: #333;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }
  }
}



nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    color: $primary;
    text-decoration: none;
    padding: 15px 30px;
    font-weight: 500;

    &.primary {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      gap: 12px;

      svg {
        margin-top: -3px;
      }
    }
  }
}


.community-slider {

  .label-wrap {
    span {
      border: 1px solid $primary;
      border-radius: 30px;
      color: $primary;
      transition: all 0.5s $ease-custom;

      &:hover {
        background: $primary;
        color: #FFF;
      }
    }
  }



  .slick-prev {
    left: 16px;
    z-index: 10;
    top: calc(50% - 30px);

    &:before {
      color: $primary;
      content: '';
      height: 20px !important;
      width: 20px !important;
      position: absolute;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="41" viewBox="0 0 23 41" fill="none"><path d="M1.24608 19.2129C0.671662 19.7874 0.671662 20.8405 1.24608 21.4149L19.6275 39.7963C20.2019 40.3707 21.255 40.3707 21.8294 39.7963C22.4039 39.2219 22.4039 38.1688 21.8294 37.5944L4.50113 20.266L21.8294 3.03345C22.4039 2.45903 22.4039 1.40593 21.8294 0.83151C21.255 0.25709 20.2019 0.25709 19.6275 0.83151L1.24608 19.2129Z" fill="%23161E47"/></svg>');
    }
  }

  .slick-next {
    right: 16px;
    z-index: 10;
    top: calc(50% - 30px);

    &:before {
      color: $primary;
      content: '';
      height: 20px !important;
      width: 20px !important;
      position: absolute;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="41" viewBox="0 0 23 41" fill="none"><path d="M21.5558 19.2129C22.1302 19.7874 22.1302 20.8405 21.5558 21.4149L3.17437 39.7963C2.59995 40.3707 1.54685 40.3707 0.972432 39.7963C0.398013 39.2219 0.398013 38.1688 0.972432 37.5944L18.3008 20.266L0.972432 3.03345C0.398013 2.45903 0.398013 1.40593 0.972432 0.83151C1.54685 0.25709 2.59995 0.25709 3.17437 0.83151L21.5558 19.2129Z" fill="%23161E47"/></svg>');
    }
  }

}

.image-carousel {
  .Slider-fig-name {
    display: block;
    margin-top: 1rem;
  }

  * {
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .slick-prev {
    left: 16px;
    z-index: 10;
    top: calc(50% - 30px);

    &:before {
      color: $primary;
      content: '';
      height: 20px !important;
      width: 20px !important;
      position: absolute;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="41" viewBox="0 0 23 41" fill="none"><path d="M1.24608 19.2129C0.671662 19.7874 0.671662 20.8405 1.24608 21.4149L19.6275 39.7963C20.2019 40.3707 21.255 40.3707 21.8294 39.7963C22.4039 39.2219 22.4039 38.1688 21.8294 37.5944L4.50113 20.266L21.8294 3.03345C22.4039 2.45903 22.4039 1.40593 21.8294 0.83151C21.255 0.25709 20.2019 0.25709 19.6275 0.83151L1.24608 19.2129Z" fill="%23161E47"/></svg>');
    }
  }

  .slick-next {
    right: 16px;
    z-index: 10;
    top: calc(50% - 30px);

    &:before {
      color: $primary;
      content: '';
      height: 20px !important;
      width: 20px !important;
      position: absolute;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="41" viewBox="0 0 23 41" fill="none"><path d="M21.5558 19.2129C22.1302 19.7874 22.1302 20.8405 21.5558 21.4149L3.17437 39.7963C2.59995 40.3707 1.54685 40.3707 0.972432 39.7963C0.398013 39.2219 0.398013 38.1688 0.972432 37.5944L18.3008 20.266L0.972432 3.03345C0.398013 2.45903 0.398013 1.40593 0.972432 0.83151C1.54685 0.25709 2.59995 0.25709 3.17437 0.83151L21.5558 19.2129Z" fill="%23161E47"/></svg>');
    }
  }

  span {
    font-size: 11px;
    opacity: 0.5;
    display: block;
    margin: 0 auto;
    margin-bottom: 3rem;
    text-align: center;
  }
}


.portfolio-page {
  h2.title-sub {
    display: none;
  }
}

.portfolio-page,
.BannerAbout,
.article-detail-page {
  padding-top: 10rem;

}

.article-detail-page {
  h2.main-title {
    font-size: 46px;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .next-case-study {
    background-color: #FFF !important;

    img {
      opacity: 0.1;
    }

    .details h4 {
      width: 60%;
    }
  }
}

h1 {
  font-size: 96px;
  font-weight: 400;
}


h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
}


.embed-image.logo-left {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 0;

  img {
    width: 80px !important;
    border: 1px solid #F1F1F1;
    height: 80px;
    object-fit: contain;
    margin-bottom: 0;
    background-color: #FFF;
  }

  .details-rightx {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    text-align: left;

    p {
      margin-bottom: 0;
    }
  }
}


.overview-section {
  text-align: left;
  padding-top: 0rem;



  .role-side {
    h5 {
      font-weight: 500;
      margin-bottom: 2rem;
    }

    p {
      font-size: 18px;
      // opacity: 0.7;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6em;
    letter-spacing: 0;
    margin-bottom: 1rem;
    // opacity: 0.7;
  }

  h4 {
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
}

hr {
  opacity: 0.1 !important;
}


.label-wrap {
  gap: 0.4rem;
  display: flex;
  margin-top: 5rem;
  flex-wrap: wrap;
  flex-direction: row;

  span {
    border: 1px solid #161e47;
    border-radius: 30px;
    color: #161e47;
    transition: all 0.5s cubic-bezier(0.06, 0.53, 0.25, 1.01);
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-weight: 500;
    padding-right: 1rem;

    &:hover {
      background-color: $primary;
      color: #FFF;
    }
  }

  // Different colors for each child using a loop
  // @for $i from 2 through 10 {
  //   span:nth-child(#{$i}) {
  //     @if $i == 2 {
  //       background-color: rgba(147, 51, 234, 0.2); // Purple
  //       color: rgb(147, 51, 234);
  //     } @else if $i == 3 {
  //       background-color: rgba(34, 197, 94, 0.2); // Green
  //       color: rgb(34, 197, 94);
  //     } @else if $i == 4 {
  //       background-color: rgba(249, 115, 22, 0.2); // Orange
  //       color: rgb(249, 115, 22);
  //     } @else if $i == 5 {
  //       background-color: rgba(239, 68, 68, 0.2); // Red
  //       color: rgb(239, 68, 68);
  //     } @else if $i == 6 {
  //       background-color: rgba(14, 165, 233, 0.2); // Light Blue
  //       color: rgb(14, 165, 233);
  //     } @else if $i == 7 {
  //       background-color: rgba(236, 72, 153, 0.2); // Pink
  //       color: rgb(236, 72, 153);
  //     } @else if $i == 8 {
  //       background-color: rgba(168, 85, 247, 0.2); // Purple
  //       color: rgb(168, 85, 247);
  //     } @else if $i == 9 {
  //       background-color: rgba(59, 130, 246, 0.2); // Blue
  //       color: rgb(59, 130, 246);
  //     } @else if $i == 10 {
  //       background-color: rgba(20, 184, 166, 0.2); // Teal
  //       color: rgb(20, 184, 166);
  //     }
  //   }
  // }
}

.text-left {
  text-align: left;
}


.metics {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .single-metics {
    width: 25%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0rem;
    padding-right: 1.2rem;
    margin: 10px;
    width: calc(25% - 20px);
    padding: 16px;
    border-radius: 15px;


    strong {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }

    p {
      font-size: .875rem;
      line-height: 1.25rem;
      margin-bottom: 0;

    }

    svg {
      opacity: 1;
    }

    .color-grey {
      color: $primary-light;
      font-size: 0.875rem;
    }

    .icon {
      border-radius: 0.5rem;
      width: 53px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.3rem;
      margin-bottom: 1.3rem;
      background: #FFF;


    }
  }
}

.op-1 {
  opacity: 10%;
}






.Banner {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;


  .right-banner {
    position: relative;
    top: 14.7rem;

    .images {
      .Main-Image {
        width: 346px;
        height: 451px;
        object-fit: cover;
        border-radius: 8px;
        object-position: top;
      }

      position: absolute;
      left: -346px;
      bottom: 0px;

      .rotation {
        position: absolute;
        right: -40px;
        bottom: -40px;

        .rotating-text-container {
          position: relative;
          width: 90px;
          /* Adjust as needed */
          height: 90px;
          background-color: #EB7846;
          border-radius: 100%;
        }

        .rotating-text-svg {
          width: 80%;
          height: 80%;
          position: absolute;
          inset: 0;
          left: 10%;
          top: 10%;
          animation: rotateText 10s linear infinite;
        }

        .rotating-text {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 3px;
          fill: #030F49;
          /* Adjust color */
        }

        .rotating-image {
          position: absolute;
          width: 30px;
          /* Adjust size as needed */
          height: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: rotateImage 10s linear infinite reverse;
        }

        .rotating-image img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        /* Animation keyframes */
        @keyframes rotateText {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(360deg);
          }
        }

        @keyframes rotateImage {
          from {
            transform: translate(-50%, -50%) rotate(0deg);
          }

          to {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }




    }
  }
}


/* Sticky sidebar */
.sidebar {
  position: sticky;
  top: 20px;
  height: max-content;
  overflow-y: auto;
  padding-top: 100px;
  padding-left: 0;

  ul {
    list-style: none;
    padding-left: 0;
    width: 100%;


  }

  li {

    button {
      color: $primary;
      font-weight: 400;
      display: block;
      padding: 10px;
      opacity: 0.5;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        width: 0;
        height: 2px;
        background-color: $primary;
        display: block;
        transition: all 0.7s $ease-custom;
      }

      &.active {
        font-weight: 600;
        opacity: 1;

        span {
          width: 50px;

        }
      }
    }
  }
}


// .pt-10{
//   padding-top: 60rem;
//   background: #CCC;
// }


.content-article {
  img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1.5rem !important;
  }
}

.portfolio-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 5rem;
  }



  .content {
    margin-top: 4rem;
    text-align: left;

    h3 {
      margin-top: 3rem !important;
      margin-bottom: 2rem;
    }

    ul,
    ol {
      margin-bottom: 3rem;
    }

    p {
      font-size: 18px;
      line-height: 1.6em;
      letter-spacing: 0;
      margin-bottom: 1rem;
      opacity: 96%;
    }

  }

  .Main-Portfolio-Image {
    min-height: 520px;
    width: 1176px;
    border-radius: 10px;

  }

}

table {

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border: 1px solid #F0F0F0;
    padding: 10px 14px;
  }

  p {
    margin-bottom: 0 !important
  }

  tbody p {
    font-size: 15px !important;
  }

  th p {
    font-size: 17px !important;
  }

}

.video-list {
  display: flex;
  /* Enable flexbox */
  flex-wrap: nowrap;
  /* Allow items to wrap to the next row */
  gap: 20px;
  /* Add spacing between videos */
  justify-content: center;
  /* Align items horizontally */
  align-items: center;
  /* Align items vertically */
}

.embed-video {
  width: 100%;

  object-fit: cover;
  /* Maintain aspect ratio */
  height: auto;
  /* Allow height to adjust */
  border-radius: 8px;
  /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Optional: Add shadow */
  video {
    width: 100%;
  }

}

.video-details {
  padding-top: 20px;
  font-size: 14px;

  strong {
    font-size: 16px;
    margin-bottom: 0.6rem !important;
    display: block;
  }

  p {
    font-size: 14px !important;
    min-height: 100px;
  }
}


.single-portfolio {
  position: relative;
  text-decoration: none;
  color: $primary;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 72px;
  display: block;
  min-height: 521px;

  .app-logo {
    position: absolute;
    top: 43px;
    z-index: 10;
    left: 60px;
  }

  &.Text-white {
    * {
      color: #FFF;
    }
  }

  .dynamic-width {
    width: var(--dynamic-width) !important;
  }

  img {
    width: 100% !important;
  }

  .portfolio-item {
    border-radius: 20px;
    overflow: hidden;

    .information {
      position: absolute;
      width: 30%;
      bottom: 50px;
      left: 60px;
      z-index: 5;



      strong {
        font-size: 18px;
        font-weight: 400;
        opacity: 0.5;
        display: block;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 3rem;
        font-weight: 500;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6em;
      }
    }

    img.thumbnail-large {
      transition: all 0.7s $ease-custom;
    }

    @media (min-width: 768px) {



      &:hover {
        img.thumbnail-large {
          scale: 1.1;
        }
      }
    }

  }

}

.Tools-Listing {
  padding-bottom: 8rem;

  .single-tool {
    margin-bottom: 2rem;

    img {
      transform: scale(0.9);
      transition: all $ease-custom 0.5s;

      &:hover {
        transform: scale(0.8);
      }
    }
  }
}

.Client-Listing {
  padding-bottom: 2rem;

  .single-client {
    // margin-bottom: 2rem;
    border: 1px solid #F0F0F0;
    outline: 1px solid #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      transform: scale(1);
      transition: all $ease-custom 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }

  }
}



.archive-wrapper {
  .border_r {
    margin-bottom: 2rem;
  }

  .row-archive {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .col-md-4 {
      padding: 0.5rem;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .thumbnail {
    overflow: hidden;

    img {
      transition: transform 0.3s ease;
      transform: scale(1);
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}


.img-rounded {
  border-radius: 12px;
  border: 1px solid #F1F1F1;
}

.embedDragabble {
  text-align: center;

  .draggable-container {
    margin-bottom: 0.7rem;

  }

  span {
    font-size: 11px;
    opacity: 0.5;
    display: block;
    margin-bottom: 3rem;
  }
}

.embed-image {
  text-align: center;

  img {
    margin-bottom: 0.7rem;
  }

  span {
    font-size: 11px;
    opacity: 0.5;
    display: block;
    margin-bottom: 3rem;
  }
}

.draggable-container {
  overflow: hidden;
  position: relative;
  border: 1px solid #F0F0F0;
  border-radius: 12px;
}

.draggable-image {
  position: absolute;
  user-select: none;
  left: 0;
  -webkit-user-drag: none;
  transform-origin: left center;
  /* Keeps scaling and positioning natural */
}

.primary {
  background-color: $primary;
  color: #FFF;
  border-radius: 99px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 15px;
  position: relative;
  gap: 6px;
  border: none;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.706592px 0.706592px -0.625px, rgba(0, 0, 0, 0.145) 0px 1.80656px 1.80656px -1.25px, rgba(0, 0, 0, 0.137) 0px 3.62176px 3.62176px -1.875px, rgba(0, 0, 0, 0.125) 0px 6.8656px 6.8656px -2.5px, rgba(0, 0, 0, 0.106) 0px 13.6468px 13.6468px -3.125px, rgba(0, 0, 0, 0.05) 0px 30px 30px -3.75px;

  svg {
    width: 16px;
  }

}

.secondary {
  background-color: #f3f4f6;
  color: #4b5563;
  border-radius: 99px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 15px;
  position: relative;
  gap: 6px;
  border: 1px solid #d1d5db;
  height: 40px;

  svg {
    width: 16px;
  }

}

.main-content {
  min-height: calc(100vh);
  /* 100vh minus the header and footer height */

}


header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  transition: all 0.5s $ease-custom;

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    /* Adds the blur effect */
    -webkit-backdrop-filter: blur(10px);
    /* Safari support */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.01);
    /* Add shadow for a separation effect */
  }

  .Logo {
    text-decoration: none;
    color: $primary;
    /* Text */

    width: 133px;
    height: 26px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;

    span {
      font-size: 22px;
      margin-top: 4px;
      font-weight: 400;
    }


  }
}


main {
  // margin-top: 72px;
  // padding-top: 72px;
}


.title-sub {
  font-size: 24px;
  font-weight: 400;
  color: $primary;
  margin-bottom: 3rem;

  span {
    color: #5e69a1;
    font-size: 16px;
    font-weight: 400;
  }
}


.Article-Listing {
  background: $grey;
  padding: 5rem;
}



.article-grid {

  a {
    text-decoration: none;
    color: $primary;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 5rem;
    width: 70%;

    .thumbnail {
      img {
        width: 325px;
        height: 265px;
        border-radius: 8px;
      }
    }

    .article-item {
      h3 {
        font-size: 28px;
        font-weight: 500;
        list-style: 1.2em;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }

      strong {
        opacity: 50%;
        font-weight: 400;
        font-size: 16;
      }

      p {
        font-size: 18px;
        line-height: 1.6em;
        font-weight: 400;
        margin-bottom: 1rem;
      }
    }

  }
}


.side-project-grid {
  .wrap {
    display: flex;
    gap: 64px;
    align-items: center;
    margin-bottom: 4rem;

    .thumbnail {
      width: 60%;

      video {
        width: 100%;
        border-radius: 10px;
      }
    }

    .article-item {
      width: 40%;

      h3 {
        font-size: 28px;
        font-weight: 500;
      }

      a {
        display: inline-flex;
        text-decoration: none;
        margin-top: 2rem;
      }
    }
  }
}


.footer {
  padding: 3rem 0;

  .email {
    font-size: 32px;
    font-weight: 400;

    a {
      margin-left: 2rem;
      color: $primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      opacity: 0.5;
    }
  }

  .social {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
  }
}


/* Fading transition */
.fade-enter {
  opacity: 0;
  transform: scale(0.98);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms $ease-custom, transform 300ms $ease-custom;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.98);
  transition: opacity 300ms $ease-custom, transform 300ms $ease-custom;
}




/* index.css or App.scss */
html,
body {
  scroll-behavior: auto !important;
}



// page transition 


.fade-enter {
  opacity: 0;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  /* Safari Prefix */
  will-change: opacity, transform;
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  /* Safari Prefix */
  transition: opacity 0.3s $ease-custom, transform 0.3s $ease-custom;
  -webkit-transition: opacity 0.3s $ease-custom, -webkit-transform 0.3s $ease-custom;
  /* Safari Prefix */
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  /* Safari Prefix */
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  /* Safari Prefix */
  transition: opacity 0.3s $ease-custom, transform 0.3s $ease-custom;
  -webkit-transition: opacity 0.3s $ease-custom, -webkit-transform 0.3s $ease-custom;
  /* Safari Prefix */
}

/* App.scss or index.css */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s $ease-custom, transform 0.3s $ease-custom;
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #aaa;
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #aaa;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// end page transition 


.single-cv-item {
  display: flex;
  gap: 30px;

  .left-side {
    font-size: 18px;
    font-weight: 400;
    width: 100px;

    p {
      opacity: 70%;
    }
  }

  .right-side {
    p {
      font-size: 18px;
      font-weight: 400;
      list-style: 1.6em;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    a {
      color: $primary;
    }
  }
}


.BannerAbout {
  h1 {
    span {
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;

  .single-gallery {
    width: calc(100% / 4);
    padding: 1rem;

    img {
      width: 100%;

      height: 300px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}



iframe.elementor-video-iframe {
  height: 500px !important;
}


.wrap-next-case {
  color: #FFF;
  text-decoration: none;
  width: 100%
}

.next-case-study {
  overflow: hidden;
  height: 200px;
  border-radius: 10px;
  position: relative;
  background-color: $primary;

  img {
    transition: all 0.5s $ease-custom;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  .details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3rem;
    z-index: 1;
    color: $primary;
    text-align: left;

    h4 {
      font-size: 2rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    opacity: 1;
    object-position: center right;
  }
}


.hamburger {
  position: absolute;
  top: 24px;
  right: 0;
  height: 40px;
  width: 70px;
  padding: 0.6rem 0;
  /* FOR DISPLAYING EVERY ELEMENT IN THE CENTER : */

  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

/* CREATING THE MIDDLE LINE OF THE HAMBURGER : */

.hamburger>div {
  position: relative;
  top: 0;
  left: 0;
  background: $primary;
  height: 2px;
  width: 60%;
  transition: all 0.4s $ease-custom;
}

/* CREATING THE TOP AND BOTTOM LINES : 
TOP AT -10PX ABOVE THE MIDDLE ONE AND BOTTOM ONE IS 10PX BELOW THE MIDDLE: */

.hamburger>div::before,
.hamburger>div::after {
  content: '';
  position: absolute;
  top: -10px;
  background: $primary;
  width: 100%;
  height: 2px;
  transition: all 0.4s $ease-custom;
  left: 0;
}

.hamburger>div::after {
  top: 10px;
}

.hamburger.isOpen>div {
  background-color: transparent;
}


.hamburger.isOpen>div::before {
  top: 0;
  transform: rotate(45deg);
  background: black;
}

/* AND ROTATING THE TOP AND BOTTOM LINES :  */

.hamburger.isOpen>div::after {
  top: 0;
  transform: rotate(135deg);
  background: black;
}



h3.warning-red {
  display: block;
  background: red !important;
  height: 100px;

}

.content {
  ul {
    li {
      list-style: none;
      position: relative;

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background-size: cover;
        position: absolute;
        left: -33px;
        top: 4px;
        opacity: 0.4;
        background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDdIMTdWMTciIHN0cm9rZT0iIzE2MUU0NyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcgMTdMMTcgNyIgc3Ryb2tlPSIjMTYxRTQ3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
      }

    }
  }

  ol {
    li {
      list-style: none;
      position: relative;

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background-size: cover;
        position: absolute;
        left: -33px;
        top: 4px;
        opacity: 1;
        background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMiAxMS4wNzk5VjExLjk5OTlDMjEuOTk4OCAxNC4xNTYzIDIxLjMwMDUgMTYuMjU0NSAyMC4wMDkzIDE3Ljk4MTdDMTguNzE4MiAxOS43MDg4IDE2LjkwMzMgMjAuOTcyMyAxNC44MzU0IDIxLjU4MzhDMTIuNzY3NCAyMi4xOTUyIDEwLjU1NzMgMjIuMTIxOCA4LjUzNDQ3IDIxLjM3NDRDNi41MTE2OCAyMC42MjcxIDQuNzg0NjUgMTkuMjQ2IDMuNjEwOTYgMTcuNDM2OUMyLjQzNzI3IDE1LjYyNzkgMS44Nzk3OSAxMy40ODc5IDIuMDIxNjggMTEuMzM2MkMyLjE2MzU2IDkuMTg0NDMgMi45OTcyMSA3LjEzNjE5IDQuMzk4MjggNS40OTY5NEM1Ljc5OTM1IDMuODU3NjggNy42OTI3OSAyLjcxNTI1IDkuNzk2MTkgMi4yNDAwMUMxMS44OTk2IDEuNzY0NzcgMTQuMTAwMyAxLjk4MjIgMTYuMDcgMi44NTk4NiIgc3Ryb2tlPSIjMTYxRTQ3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOSAxMUwxMiAxNEwyMiA0IiBzdHJva2U9IiMxNjFFNDciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
      }

    }
  }
}


@media (min-width: 768px) {

  .visible-mobile {
    display: none;
  }

}

@media (max-width: 820px) {

  .animated-line {
    position: absolute;
    left: auto;
    top: auto;
    z-index: 10;
    opacity: 1;
    transform: translateX(-5%) translateY(59%) rotateZ(130deg) scale(0.5);
    bottom: 0;
    right: 0;
}


  .embed-image.logo-left:after {
    display: none;
  }

  .BannerAbout h1 span {
    margin-left: 0.5rem !important;
  }

  .about-page h1.d-flex.align-items-end.location-heading svg {
    margin-left: 0.5rem !important;
  }

  header {
    height: 60px;
  }

  .linkedin-mobile {
    position: fixed;
    top: 10px;
    right: 75px;
    display: flex !important;
    text-decoration: none;
    z-index: 1999;
  }

  .col-md-2.single-tool {
    width: 33%;
  }

  .col-md-2.single-client {
    width: 33%;
  }

  body {
    overflow-x: hidden;

    &.menu-open {
      overflow: hidden;
      height: 100vh;
      width: 100vw;
    }
  }

  nav.col-10.text-right {
    width: 50%;
    padding-right: 0;
  }

  main {
    padding-top: 0px;
  }

  nav a.primary {
    display: none;
  }



  nav {
    .nav-wrap {
      position: absolute;
      width: 100%;
      height: 100vh;
      background: #FFF;
      left: 0;
      top: 58px;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;



      .home-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 150px;
        height: 70px;
      }
    }

    &.isOpen .nav-wrap {
      display: flex;
    }
  }

  .nav-wrap {
    display: none;
  }

  .HomePage {
    padding-top: 4rem;
  }

  .banner-text-container {
    margin-top: 3rem;
  }

  .banner-text-animated {
    text-align: left;
  }

  .single-portfolio .dynamic-width {
    max-width: 200px !important;
    width: 120px;
    height: auto !important;
    left: 20px;
    top: 20px;
  }

  .single-portfolio .portfolio-item .information h3 {
    font-size: 2.3rem;
  }

  .Client-Listing .col-md-3.single-client {
    width: 50%;
  }

  .Community-Listing.mt-5 {
    margin-top: 0 !important;
    padding-top: 0;
  }

  .Side-projects.mt-5.pt-3 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .footer {
    padding: 32px !important;
  }

  .Tools-Listing {
    padding-bottom: 4rem;
  }

  .article-grid a:last-child {
    margin-bottom: 0;
  }

  h1.banner-text-animated {
    line-height: 1;
    margin-bottom: 0rem;
    font-size: 46px !important;
    text-align: left;
    margin: 6px 0 !important;
  }

  .Banner {
    flex-direction: column-reverse;
    padding-top: 0;
    margin-bottom: 6rem;



    .w-50 {
      width: 100% !important;
    }

    .right-banner {
      top: 0;

      .images {
        position: static;

        .rotation {
          right: -12px;
        }
      }
    }

    h1 {
      font-size: 3rem;
      margin-top: 2rem;
    }
  }

  .portfolio-grid {
    .single-portfolio {
      border-radius: 8px;
      margin-bottom: 2rem;

      .portfolio-item {
        flex-direction: column-reverse;
        display: flex;
        border-radius: 8px;
        border: 1px solid #CCC;

        .information {
          width: 100%;
          position: static;
          padding: 20px;
        }


        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }
    }
  }

  .Article-Listing {
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .article-grid {
      a {
        flex-direction: column;
        width: 100%;

        .thumbnail {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }
  }



  .Community-Listing {
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .community-grid {
      a {
        flex-direction: column;
        width: 100%;

        .thumbnail {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }
  }





  .side-project-grid {
    .wrap {
      flex-direction: column;

      .thumbnail {
        width: 100%;
      }

      .article-item {
        width: 100%;
      }
    }
  }

  .footer {
    margin: 16px;
    width: calc(100% - 32px);
    padding-inline: 32px;
    background-color: $grey;
    border-radius: 8px;

    .row {
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .email {
        span {
          display: block;
        }

        font-size: 24px;

        a {
          margin-left: 0rem;
        }
      }

      .social {
        justify-self: start;
      }
    }
  }

  .hamburger {
    display: flex;
    justify-content: flex-end;
    right: 18px;
    top: 10px;
  }

  .BannerAbout {
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 3rem;
  }

  .container.mt-5.pt-5.mb-5.pb-5 {
    padding: 0;
  }

  .mt-4.pt-4.mb-4.pb-4.archive-wrapper .container {
    padding: 0;
  }

  .single-cv-item {
    flex-direction: column;
    position: relative;



    .left-side {
      font-size: 1.2rem;
      width: 100%;
      position: sticky;
      padding: 16px 0;
      top: 60px;
      z-index: 4;
      background-color: #FFF;

      p {
        margin-bottom: 0;
      }
    }

  }

  .gallery-grid {

    .single-gallery {
      padding: 5px;
      width: 50%;
    }

  }

  .portfolio-detail-page {
    width: 100%;

    .sidebar {
      display: none;
    }

    .label-wrap {
      margin-top: 0;
    }

    .next-case-study {
      img {
        opacity: 0.3;
      }

      .details {
        left: 2rem;
      }
    }

    .col-md-9.content {
      margin: 0;

      h3 {
        margin-top: 1.5rem !important;
        margin-bottom: 0.5rem;
      }

      table {
        display: block;
        width: auto;
        overflow-x: scroll;
      }
    }

    .portfolio-Banner {
      width: calc(100% - 32px);
      border-radius: 30px;
      margin-top: 70px !important;

      .side-info {
        padding-bottom: 0 !important;

        h2 {
          font-size: 45px;
        }

        .data-bottom {
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          .single-info {
            width: calc(50% - 0.5rem);
          }
        }
      }
    }

    .portfolio-Banner .side-info {
      padding: 24px;
      padding-bottom: 0;
    }

    img.Main-Portfolio-Image {
      width: 100%;
      height: auto;
      min-height: auto;
    }

    .content {
      h3 {
        font-size: 1.2rem;
        width: 100%;
        position: sticky;
        padding: 16px 0;
        top: 60px;
        z-index: 4;
        background-color: #FFF;
      }

    }

  }

  .about-page {
    .banner-content {
      text-align: left;
    }

    .BannerAbout {
      padding-top: 110px !important;
      padding-bottom: 0rem;
      min-height: auto;
    }

    h1.d-flex.align-items-end.location-heading {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center !important;
      justify-content: flex-start;

      svg {
        height: 43px;
      }
    }
  }

  .article-detail-page {
    padding-top: 110px !important;

    .container {
      padding: 0 !important;
    }

    .next-case-study {
      .details {
        left: 20px;

        h4 {
          width: 100%;
          font-size: 20px;
          line-height: 1.4;
        }
      }
    }
  }

  .archive-wrapper {
    padding: 16px !important;
  }

  .visible-mobile {
    display: block;
  }



  .article-detail-page {
    img {
      width: 100%;
    }
  }

  iframe.elementor-video-iframe {
    height: auto !important;
  }

  .container {
    padding: 0 20px;
  }

  .side-project-grid .wrap {
    gap: 16px;

    .article-item a {
      margin-top: 1rem
    }
  }

  .footer .social {
    text-align: left;
    justify-content: flex-start;
  }

  .no-ps-mobile {
    padding-left: 0 !important;
  }


}



@media (min-width: 820px) {

  .single-cv-item .left-side p {
    position: sticky;
    top: 100px;
  }

  .col-w-90 {
    width: 89% !important;
  }

  .about-page {
    .embed-image.logo-left {
      margin-left: -7rem;

      img {
        margin-right: 1rem;
      }
    }

    .right-side.col {
      padding-left: 7rem;
    }
  }

}




// ipad landscape

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .container {
    max-width: 1130px !important;
  }

  h1.banner-text-animated {
    font-size: 85px !important;
  }

  .HomePage {
    padding-top: 5rem;
  }

  .single-portfolio .portfolio-item .information {
    width: 40%;
  }

  .App {
    overflow-x: hidden;
  }

  .portfolio-Banner .side-info {
    padding: 4rem 0;

  }

  .portfolio-Banner {
    margin: 5rem 2rem !important;
    width: calc(100% - 4rem);
    border-radius: 30px;
  }

  .portfolio-Banner .side-info .col-md-6 {
    width: 80%;
  }

  // close 
}

// end of ipad landscape 


// start ipad portrait 



// Vibration animation
@keyframes vibrate {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

.contact-qr {
  &__container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 50;
  }

  &__button {
    background-color: #161e47;
    color: white;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    border: none!important;
  }

  &__card {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 208px;
    transition: all 0.3s;
  }

  &__header {
    background-color: #161e47;
    color: white;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  &__close-button {
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s;
    
    &:hover {
      color: #e5e7eb;
    }
  }

  &__tab-container {
    padding: 12px;
  }

  &__tabs {
    background-color: #f3f4f6;
    padding: 4px;
    border-radius: 50px;
    display: flex;
    position: relative;
  }

  &__tab-indicator {
    position: absolute;
    top: 4px;
    bottom: 4px;
    border-radius: 50px;
    background-color: #161e47;
    transition: all 0.3s ease-in-out;
    
    &--email {
      left: 4px;
      right: 50%;
    }
    
    &--call {
      left: 50%;
      right: 4px;
    }
  }

  &__tab {
    padding: 8px 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.3s;
    cursor: pointer;
    border: none;
    background: none;
    
    &--active {
      color: white;
    }
    
    &--inactive {
      color: #4b5563;
    }
  }

  &__tab-label {
    display: flex;
    align-items: center;
  }

  &__tab-text {
    margin-left: 4px;
    font-size: 14px;
  }

  &__qr-container {
    padding: 0 16px 8px;
    display: flex;
    justify-content: center;
  }

  &__qr-wrapper {
    padding: 8px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }
  }

  &__instruction-text {
    padding: 0 16px 4px;
    text-align: center;
    font-size: 12px;
    color: #6b7280;
  }

  &__contact-info {
    padding: 0 16px 16px;
    text-align: center;
    font-size: 12px;
    color: #4b5563;
    
    p {
      margin: 0;
    }
  }
}



// qr code 


/* Additional styles for protecting phone number from crawlers */
.contact-qr__contact-number {
  cursor: pointer;
  position: relative;
  margin: 0;
  transition: color 0.2s ease;
}

.contact-qr__contact-number:hover {
  color: #161e47;
  text-decoration: underline;
}

/* This helps prevent bots from recognizing the pattern */
.contact-qr__contact-number::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* This creates an invisible layer over the number */
}

/* Phone number is split into parts for added protection */
.contact-qr__phone-part {
  display: inline-block;
  /* Direction is flipped and then visually corrected to confuse crawlers */
  direction: rtl;
  unicode-bidi: bidi-override;
}

/* For bots that analyze styles */
.contact-qr__phone-part::after {
  content: attr(data-value);
  display: none;
  /* Hidden content that contains incorrect data to mislead crawlers */
}

/* Adds accessibility while maintaining security */
.contact-qr__accessibility-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.small-text{
  font-size: 14px;
}

.underline{
  text-decoration: underline;
}

.contact-qr__button{
  animation: pulse 2s infinite;
  border:none;

}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

